import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// Libraries
import { map as _map, size as _size } from 'lodash'

// Components
import {
  BoxFirst,
  BoxMiddle,
  BoxLast,
  LineDefault,
  TitleDefault,
  TitleAlt,
  ContentDefault,
  ButtonDefault,
} from 'components/Elements'

// Language
import { getLanguage } from 'services/language'

// CSS
import './WhyUs.scss'

// Icons
import checkMarkWhite from 'img/icon/check-mark-white.svg'

const WhyUs = () => {
  const { whyusNL, whyusEN } = useStaticQuery(graphql`
    {
      whyusNL: wordpressWpComponenten(
        slug: { eq: "waarom-ons" }
        wpml_current_locale: { eq: "nl_NL" }
      ) {
        ...whyusContent
      }
      whyusEN: wordpressWpComponenten(
        slug: { eq: "waarom-ons" }
        wpml_current_locale: { eq: "en_US" }
      ) {
        ...whyusContent
      }
    }

    fragment whyusContent on wordpress__wp_componenten {
      acf {
        title
        subtitle
        description
        points {
          point
        }
        buttonText: button_text
        buttonLocation: button_location
      }
    }
  `)

  const language = getLanguage()
  const whyusContent = language === 'nl_NL' ? whyusNL : whyusEN

  return (
    <div className="why-us position-relative py-5">
      <div className="why-us-big-circle d-none d-lg-block" />
      <div className="container py-5">
        <div className="row">
          <div className="col-12 col-lg-7 pr-lg-5 d-none d-lg-block">
            {_map(whyusContent.acf.points, ({ point }, index) => (
              <Point
                key={index}
                index={index}
                maxIndex={_size(whyusContent.acf.points) - 1}
                text={point}
              />
            ))}
          </div>
          <div className="col-12 col-lg-5 text-lg-right">
            <LineDefault className="ml-lg-auto" />
            <TitleAlt className="mt-3 pr-lg-3">
              {whyusContent.acf.subtitle}
            </TitleAlt>
            <TitleDefault className="pr-lg-5">
              {whyusContent.acf.title}
            </TitleDefault>
            <ContentDefault className="mt-5">
              <div
                dangerouslySetInnerHTML={{
                  __html: whyusContent.acf.description,
                }}
              />
            </ContentDefault>
            <div className="text-left">
              <ButtonDefault
                className="mt-5"
                to={whyusContent.acf.buttonLocation}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: whyusContent.acf.buttonText,
                  }}
                />
              </ButtonDefault>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Point = ({ index, maxIndex, text }) => {
  let Wrapper = BoxMiddle

  if (index === 0) Wrapper = BoxFirst
  else if (index === maxIndex) Wrapper = BoxLast

  return (
    <Wrapper className="why-us-point mb-3 py-3 pr-4">
      <div className="row">
        <div className="col-2 d-lg-flex justify-content-end align-items-center">
          <div className="why-us-point-icon">
            <img src={checkMarkWhite} alt="" />
          </div>
        </div>
        <div className="col-10">{text}</div>
      </div>
    </Wrapper>
  )
}

export default WhyUs
