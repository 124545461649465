import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { map as _map, size as _size } from 'lodash'

// Components
import {
  LineDefault,
  TitleAlt,
  TitleDefault,
  ContentDefault,
  ButtonDefault,
  BoxFirst,
  BoxMiddle,
  BoxLast,
  ButtonGoAlt,
} from 'components/Elements'

// Language
import { getLanguage } from 'services/language'

// CSS
import './Partnering.scss'

const Partnering = () => {
  const { partnerNL, partnerEN } = useStaticQuery(graphql`
    {
      partnerNL: wordpressWpComponenten(
        wpml_current_locale: { eq: "nl_NL" }
        slug: { eq: "partnerprogramma-text" }
      ) {
        ...partnerContent
      }

      partnerEN: wordpressWpComponenten(
        wpml_current_locale: { eq: "en_US" }
        slug: { eq: "partnerprogramma-text" }
      ) {
        ...partnerContent
      }
    }

    fragment partnerContent on wordpress__wp_componenten {
      acf {
        textTitle: text_title
        textSubtitle: text_subtitle
        textDescription: text_description
        buttonText: button_text
        buttonLocation: button_location
        items: program_items {
          title
          location
        }
      }
    }
  `)

  const language = getLanguage()
  const partnerContent = language === 'nl_NL' ? partnerNL.acf : partnerEN.acf

  return (
    <div className="partnering row">
      <div className="col-12 col-lg-8 pr-lg-5">
        <LineDefault />
        <TitleDefault className="mt-3">{partnerContent.textTitle}</TitleDefault>
        <TitleAlt className="pl-lg-5">{partnerContent.textSubtitle}</TitleAlt>
        <ContentDefault className="mt-4">
          <div
            dangerouslySetInnerHTML={{ __html: partnerContent.textDescription }}
          />
        </ContentDefault>
        <ButtonDefault
          className="mt-5 d-none d-lg-inline-block"
          to={partnerContent.buttonLocation}
        >
          <div
            dangerouslySetInnerHTML={{ __html: partnerContent.buttonText }}
          />
        </ButtonDefault>
      </div>
      <div className="col-12 col-lg-4 mt-5 mt-lg-0">
        {_map(partnerContent.items, ({ title, location }, index) => (
          <Programm
            key={index}
            index={index}
            maxIndex={_size(partnerContent.items) - 1}
            title={title}
            location={location}
          />
        ))}
      </div>
      <div className="col mt-4 d-inline-block d-lg-none">
        <ButtonDefault to="/demo">
          Lees meer over 
          {' '}
          <b>PBXcomplete</b>
        </ButtonDefault>
      </div>
    </div>
  )
}

const Programm = ({ index, maxIndex, title, location }) => {
  let Wrapper = BoxMiddle

  if (index === 0) Wrapper = BoxFirst
  else if (index === maxIndex) Wrapper = BoxLast

  return (
    <Wrapper className="partnering-programm mb-3 py-3 px-4">
      <div className="row">
        <div className="col-10 d-flex justify-content-start align-items-center">
          {title}
        </div>
        <div className="col-2 d-flex justify-content-end align-items-center">
          <ButtonGoAlt to={location} />
        </div>
      </div>
    </Wrapper>
  )
}

export default Partnering
