import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

// Components
import { ButtonAltDefault } from 'components/Elements'

// Language
import { getLanguage } from 'services/language'

// CSS
import './Testimonial.scss'

const Testimonial = () => {
  const { testimonialNL, testimonialEN } = useStaticQuery(graphql`
    {
      testimonialNL: wordpressWpComponenten(
        slug: { eq: "testimonial" }
        wpml_current_locale: { eq: "nl_NL" }
      ) {
        ...testimonialContent
      }
      testimonialEN: wordpressWpComponenten(
        slug: { eq: "testimonial" }
        wpml_current_locale: { eq: "en_US" }
      ) {
        ...testimonialContent
      }
    }

    fragment testimonialContent on wordpress__wp_componenten {
      acf {
        quote
        companyName: company_name
        buttonText: button_text
        buttonLocation: button_location
        image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  `)

  const language = getLanguage()
  const testimonialContent =
    language === 'nl_NL' ? testimonialNL : testimonialEN

  return (
    <div className="testimonial color-background-main">
      <BackgroundImage
        className="testimonial-background"
        fluid={testimonialContent.acf.image.localFile.childImageSharp.fluid}
      />
      <div className="testimonial-gradient" />
      <div className="container">
        <div className="row py-2">
          <div className="col-12 col-lg-7" />
          <div className="col-12 col-lg-5 py-4">
            <div className="testimonial-block p-3">
              <div className="testimonial-block-top">
                <div className="testimonial-block-top-quote">&ldquo;</div>
              </div>
              <div className="font-family-secondary font-weight-s color-text-light px-3">
                <div
                  className="testimonial-block-content font-size-xm"
                  dangerouslySetInnerHTML={{
                    __html: testimonialContent.acf.quote,
                  }}
                />
                <div className="mt-3 text-right font-size-m font-style-italic pr-5">
                  - {testimonialContent.acf.companyName}
                </div>
              </div>
              <div className="text-right mt-4">
                <ButtonAltDefault to={testimonialContent.acf.buttonLocation}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: testimonialContent.acf.buttonText,
                    }}
                  />
                </ButtonAltDefault>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Testimonial
